import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import ErrorHandlerService from "@/modules/common/services/error-handler.service";
import Container from "typedi";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Notification from '@/modules/common/services/notification.service'

Component.registerHooks([
    'beforeRouteLeave'
])

@Component({
    name: 'GtrSurveysSessionView'
})
export default class GtrSurveysSessionView extends GtrSuper {
    @Prop()
    allContent: any

    @Prop()
    design: any

    @Prop()
    participant: any

    data() {
        return {
            title: '',
            session: null,
            surveysFields: [],
            quizzesFields: [],
            tab: null,
            completed: false,
            surveyType: null,
            surveyPage: 1,
            currentLanguage: 'en',
            operatorFunctions: {
                '=': (a: any, b: any) => {
                    if (typeof a === 'object' && typeof b === 'object') {
                        // get option group of field.
                        const fieldOptionGroupOptions = this.allContent.data.option_groups.filter((group: any) => {
                            return group.uuid == a.optionGroup;
                        })[0].options;
                        const showIfGroupOptions = this.allContent.data.option_groups.filter((group: any) => {
                            return group.uuid == b.optionGroup;
                        })[0].options;

                        // get option value;
                        const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
                            return option.uuid == a.value;
                        });
                        const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
                            return option.uuid == b.value;
                        });
                        // if no value match, default to zero. Otherwise use the value given.
                        const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name;
                        const bValue = showIfOptionValue.length === 0 ? 0: showIfOptionValue[0].name;

                        return aValue === bValue
                    } else if (typeof a === 'string' && typeof b === 'string') {
                        return a === b;
                    }
                },
                '!=': (a: any, b: any) => a !== b,
                '>': (a: any, b: any) => parseInt(a) > parseInt(b),
                '<': (a: any, b: any) => parseInt(a) < parseInt(b),
                '>=': (a: any, b: any) => {
                    if (typeof a == 'object' && typeof b == 'object') {

                        // get option group of field.
                        const fieldOptionGroupOptions = this.allContent.data.option_groups.filter((group: any) => {
                            return group.uuid == a.optionGroup;
                        })[0].options;
                        const showIfGroupOptions = this.allContent.data.option_groups.filter((group: any) => {
                            return group.uuid == b.optionGroup;
                        })[0].options;

                        // get option value;
                        const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
                            return option.uuid == a.value;
                        });
                        const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
                            return option.uuid == b.value;
                        });

                        // if no value match, default to zero. Otherwise use the value given.
                        const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name;
                        const bValue = showIfOptionValue.length === 0 ? 0: showIfOptionValue[0].name;

                        // compare and return.
                        return parseInt(aValue) >= parseInt(bValue);
                    } else {
                        return parseInt(a) >= parseInt(b)
                    }
                },
                '<=': (a: any, b: any) => parseInt(a) <= parseInt(b),
                '^=': (a: any, b: any) => (a || '').includes(b),
                '!^=': (a: any, b: any) => !(a || '').includes(b),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                '=null': (a: any, b = null) => a === null || a === '',
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                '!=null': (a: any, b = null) => a !== null && a !== ''
            }
        }
    }

    @Watch('allContent')
    async onAllContentChange (payload: any) {
        if (payload && this.participant.uuid) {
            await this.getSession()
        }
    }

    beforeRouteLeave (from, to, next) {
        if (!this.$data.completed) {
            if (confirm('You have not finished and will lose your progress. Do you really want to cancel?')) {
                next()
            } else {
                next(false)
            }
        } else {
            next()
        }
    }

    async mounted() {
       try {
           this.$store.dispatch('common/showLoader', { value: true })
            if (!this.$store.state.auth.participant.uuid) {
                const event_identifier = this.$route.params.event_identifier
                if (event_identifier){
                    await this.$store.dispatch('auth/getParticipant', {
                        event_identifier: event_identifier
                    })
                }
            }
            if (Object.keys(this.allContent).length > 0 && this.participant.uuid) {
              await this.getSession()
            }
       } catch (error) {
           Container.get(ErrorHandlerService).error(error)
       } finally {
            this.$store.dispatch('common/hideLoader')
       }
    }

    get primaryButtonColor (): string {
        return this.design?.default_primary_button_color || 'var(--teal-300)'
    }

    get secondaryButtonColor (): string {
        return this.design?.default_secondary_button_color || 'var(--teal-17)'
    }

    get settings () {
        return this.allContent?.data?.evals_settings ?? {}
    }

    get instructions (): string {
        return this.settings[this.$data.surveyType + '_instructions']?.[this.$data.currentLanguage] ?? ''
    }

    get questionLabelFormatting () {
        const formatting = {}
        // if bold in default_question_label_formatting add fontWeight: 'bold' to formating object. if italic add fontStyle: 'italic', if underline add textDecoration: 'underline' otherwise return empty object.
        if (this.design.default_question_label_formatting) {
            if (this.design.default_question_label_formatting.includes('bold')) {
                formatting['fontWeight'] = 'bold'
            }
            if (this.design.default_question_label_formatting.includes('italics')) {
                formatting['fontStyle'] = 'italic'
            }
            if (this.design.default_question_label_formatting.includes('underline')) {
                formatting['textDecoration'] = 'underline'
            }
        } else {
            return {}
        }
        return formatting
    }

    ratings (field: any): string[] {
        const range: string[] = []
        const start = Number(field.range.start)
        const end = Number(field.range.end)
        if (start < end) {
            for (let i = start; i <= end; i++) {
                range.push(i.toString())
            }
        }
        return range
    }

    toDashboard () {
        this.$router.push({
            name: 'surveys.index',
            params: {
                event_identifier: this.$route.params.event_identifier
            }
        })
    }

    cancel () {
        this.toDashboard()
    }

    async handleComplete() {
        try {
            this.$store.dispatch('common/showLoader', { value: true })
            const body: any[] = []
            for (let i =0; i < this.$data.session.pageData.fields.length; i++) {
                const field = this.$data.session.pageData.fields[i]
                body.push({
                    field: field.id,
                    value: field.current_value ?? '',
                    session_speaker_uuid: null
                })
            }
            const payload: any = {
                event_uuid: this.allContent.data.event.uuid,
                session_uuid: this.$route.params.session_uuid,
                body: body
            }
            await this.$store.dispatch('surveys/submitSurveyAnswer', payload)
            this.$data.completed = true
            // if we get to this point, then the quiz was passed. Send notification of success.
            const passVerbiage = this.settings.quiz_pass_verbiage[this.$data.currentLanguage]
            Container.get(Notification).success(passVerbiage)
            // route the user to the survey finish page.
            this.$router.push({
                name: 'surveys.session.finish',
                params: {
                    'event_identifier': this.$route.params.event_identifier,
                    'session_uuid': this.$route.params.session_uuid
                }
            })
        } catch (error) {
            if (error.response.data.error_code === 'BAD_SCORE') {
                const failVerbiage = this.settings.quiz_fail_verbiage[this.$data.currentLanguage]
                const message = failVerbiage ?? 'You did not pass the quiz. Do you want to correct your answers and try again now?'
                if (!confirm(message)) {
                    this.toDashboard()
                }
            } else if (error.response.data.error_code === 'TOO_MANY_FAILS') {
                const maxFailsVerbiage = this.settings.quiz_max_fails_verbiage[this.$data.currentLanguage]
                Container.get(Notification).error(maxFailsVerbiage)
            } else {
                Container.get(ErrorHandlerService).error(error)
            }
        } finally {
            this.$store.dispatch('common/hideLoader')
        }
    }

    private async getSession () {
        if(this.$store.state.auth.participant.uuid) {
            const session_uuid = this.$route.params.session_uuid
            const response = await this.$store.dispatch('surveys/getSession', {
                event_uuid: this.allContent.data.event.uuid,
                participant_uuid: this.$store.state.auth.participant.uuid,
                session_uuid: session_uuid,
                event_identifier: this.$route.params.event_identifier
            })
            const session = response.data
            if (session_uuid === 'general') {
                this.$data.surveyType = 'general'
                this.$data.title = 'General Conference Feedback'
            } else if (session_uuid === 'keynote') {
                this.$data.surveyType = 'keynote'
                this.$data.title = 'Keynote Session Feedback'
            } else if (session?.session?.name) {
                this.$data.surveyType = 'session'
                this.$data.title = 'Session: ' + session.session.name
            }
            if (session?.pageData?.fields) {
                if (!Array.isArray(session.pageData.fields) && typeof session.pageData.fields === 'object') {
                    // convert fields object to an array
                    const fieldArray: any[] = []
                    Object.keys(session.pageData.fields).forEach(key => {
                        fieldArray.push(session.pageData.fields[key])
                    })
                    session.pageData.fields = fieldArray
                }
                this.$data.session = session
                this.$data.session.pageData.fields.forEach(field => {
                    if (field.type === 'checkbox') {
                        field.current_value = []
                    }
                    if (this.showIfChecker(field)) {
                        if (field.isQuiz === 1) {
                            this.$data.quizzesFields.push(field)
                        } else {
                            this.$data.surveysFields.push(field)
                        }
                    }
                })
                if (this.$data.surveysFields.length === 0) {
                    this.$data.surveyPage = 2
                }
            }
        }
    }

    // I ran into a bug where if I selected all the checkboxes in my test, only two would end up in the answer.
    updateCheckboxAnswer(fieldKey, value) {
        // get the question at the index
        let question
        if (this.$data.surveyType === 'general' || this.$data.surveyType === 'keynote') {
            question = this.$data['quizzesFields'][fieldKey]
        } else {
            question = this.$data['surveysFields'][fieldKey]
        }
        // is my selection selected?
        const valueExists = question.current_value.indexOf(value)
        // No? add it. Yes? remove it.
        if (valueExists === -1) {
            question.current_value.push(value)
        } else {
            question.current_value.splice(valueExists, 1)
        }
        // overwrite the old version of the question with a rebuilt one.
        // I did this to prevent issues with re-renders with nested objects.
        if (this.$data.surveyType === 'general' || this.$data.surveyType === 'keynote') {
            this.$data['quizzesFields'][fieldKey] = question
        } else {
            this.$data['surveysFields'][fieldKey] = question
        }
    }

    /*
     * Show-if
     */

    showIfChecker(field: any): boolean {
        // if field.visible is undefined
        if (field.visible === undefined) {
            // create it and set it to true.
            field.visible = true;
        }

        // if field visible is false
        if (!field.visible) {
            // if it's hidden, make sure it isn't required also.
            field.required = false
            // return false so showIf will hide the field.
            return false
        }
        if (!Object.keys(field.show_if).length || !field?.show_if?.type) {
            return true
        }
        if (field.show_if.type === 'grouping') {
            const childGroupResults: any = []
            for (const parentGroupItem of field.show_if.group_items) {
                if (parentGroupItem.group_operator === 'AND') {
                    childGroupResults.push(parentGroupItem.group_items.every(item => this.evaluateSingleShowIfs(item)))
                } else {
                    childGroupResults.push(parentGroupItem.group_items.some(item => this.evaluateSingleShowIfs(item)))
                }
            }
            if (field.show_if.group_operator === 'AND') {
                return childGroupResults.every(result => result)
            }
            return childGroupResults.some(result => result)
        } else {
            return this.evaluateSingleShowIfs(field.show_if)
        }
    }

    evaluateSingleShowIfs(field: any): boolean {
        if (field.type === 'field_criteria') {
            const participantFieldValue = this.participant.participant_data?.[field.field]
            if (this.$data.operatorFunctions[field.operator](participantFieldValue, field.value)) {
                return true
            }
        } else if (field.type === 'option_criteria') {
            const participantSelectedOptionGroupOptions = this.participant.selected_options?.filter((selected_option: any) => selected_option.option_group.uuid === field.field)
            if (participantSelectedOptionGroupOptions.length) {
                for (let i = 0; i < participantSelectedOptionGroupOptions.length; i++) {
                    const participantSelectedValue = participantSelectedOptionGroupOptions[i].option.uuid
                    if (this.$data.operatorFunctions[field.operator](participantSelectedValue, field.value)) {
                        return true
                    }
                }
            }
        } else {
            return true
        }
        return false
    }
}
